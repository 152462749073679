import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import get from 'lodash/get'

import MetaTags from '@objects/metatags'
import SearchResults from '@components/searchresults'

function SearchResultPage({ data }) {
  const searchIndex = get(data, 'siteSearchIndex.index')

  return (
    <>
      <MetaTags meta={{ noIndex: true }} />
      <SearchResults searchIndex={searchIndex} />
    </>
  )
}

SearchResultPage.propTypes = {
  data: PropTypes.any,
  pageContext: PropTypes.object,
}

export default SearchResultPage

export const pageQuery = graphql`
  query SearchResultPageIndexQuery {
    siteSearchIndex {
      index
    }
  }
`

import React, { useState, useEffect, useContext } from 'react'
import { useIntl } from 'react-intl'
import { navigate, Link } from 'gatsby'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import clsx from 'clsx'
import { useLocation } from '@reach/router'
import { makeStyles } from '@material-ui/core/styles'

import TrackingContext from '@providers/trackingProvider'

import { Index } from 'elasticlunr'
import queryString from 'query-string'
import { FormattedMessage } from 'react-intl'

import Tabs, { TabHead, TabPanel } from '@objects/tabs'
import Container from '@objects/container'
import Button from '@objects/button'
import SearchInput from '@objects/searchinput'
import { TeaserCopy } from '@objects/copy'
import SearchResultIcon from '@objects/searchResultIcon'

const useStyles = makeStyles((theme) => ({
  link: {
    '&:focus-visible > div': {
      outline: '2px solid blue',
    },
  },
}))

const StyledSearchResultContent = styled.div.attrs({
  className: 'py-5',
})`
  border-bottom: 1px solid ${({ theme }) => theme.colors['gray-light'][650]};
`

function SearchResults({ className, searchIndex }) {
  const location = useLocation()
  const TrackingCtx = useContext(TrackingContext)
  const intl = useIntl()
  const [index, setIndex] = useState(null)

  const [query, setQuery] = useState('')
  const [resultsAll, setResultsAll] = useState([])
  const [resultsNews, setResultsNews] = useState([])
  const [resultsDownloads, setResultsDownloads] = useState([])
  const [resultFaqs, setResultsFaqs] = useState([])

  const style = useStyles()

  useEffect(() => {
    if (!index) setIndex(Index.load(searchIndex))
  }, [])

  useEffect(() => {
    setQuery(queryString.parse(location.hash).searchquery)
  }, [location.hash])

  useEffect(() => {
    if (!query.length) return
    search(query)
  }, [query])

  useEffect(() => {
    setResultsNews(
      resultsAll.filter(
        (result) => result.type === 'newsroom' || result.type === 'blog'
      )
    )
    setResultsDownloads(
      resultsAll.filter((result) => ['Pdf', 'Media'].includes(result.type))
    )
    setResultsFaqs(resultsAll.filter((result) => result.type === 'Faq'))
  }, [resultsAll])

  function search(searchquery) {
    setResultsAll(
      index
        .search(searchquery, {})
        .map(({ ref }) => index.documentStore.getDoc(ref))
        .filter((result) => result.locale === intl.locale)
    )

    navigate(`${location.pathname}#searchquery=${searchquery}`, {
      replace: true,
    })
  }

  function TabHeadClickEvent(name) {
    TrackingCtx.PushMessage('custom.search-tabchanged', {
      message: name,
    })
  }

  function results(results) {
    return results.map((result, i) => {
      const classes = clsx(result.type, style.link)
      return (
        <Link
          key={`${result.path}${i}`}
          className={classes}
          to={result.path}
          tabIndex={0}
        >
          {renderResultInner(result)}
        </Link>
      )
    })
  }

  function renderResultInner(result) {
    return (
      <>
        <StyledSearchResultContent tabIndex={0}>
          <div className="mb-2 font-medium text-blue">
            <SearchResultIcon
              type={result.type}
              className="mr-1 align-text-top"
            />
            <FormattedMessage id={'search.result.' + result.type} />
          </div>
          <h5 className="mb-2">
            {result.title}
            {!result.copy && <Button textlink />}
          </h5>
          {result.copy && (
            <TeaserCopy truncate={20} truncateextend={<Button textlink />}>
              {result.copy}
            </TeaserCopy>
          )}
        </StyledSearchResultContent>
      </>
    )
  }

  return (
    <div className={clsx(className)}>
      <Container nopadding width="md" className="pt-10 pb-2">
        <SearchInput
          resultsAll={resultsAll}
          searchfunc={search}
          searchquery={query}
          noautosuggest
        />
      </Container>
      <Container nopadding width="md" className="pt-0 pb-6">
        {resultsAll.length > 0 ? (
          <>
            <h1 className="pt-2">
              <FormattedMessage
                id={
                  resultsAll.length > 1
                    ? 'search.result.plural'
                    : 'search.result.singular'
                }
                values={{
                  searchterm: <strong>{query}</strong>,
                }}
              />
            </h1>
            <Tabs className="">
              <TabHead onClick={() => TabHeadClickEvent('resultsAll')}>
                <FormattedMessage id="search.result.all" />
                <span className="ml-2">({resultsAll.length})</span>
              </TabHead>
              <TabHead onClick={() => TabHeadClickEvent('resultsNews')}>
                <FormattedMessage id="search.result.news" />
                <span className="ml-2">({resultsNews.length})</span>
              </TabHead>
              <TabHead onClick={() => TabHeadClickEvent('resultFaqs')}>
                <FormattedMessage id="search.result.Faq" />
                <span className="ml-2">({resultFaqs.length})</span>
              </TabHead>
              <TabHead onClick={() => TabHeadClickEvent('resultsDownloads')}>
                <FormattedMessage id="search.result.downloads" />
                <span className="ml-2">({resultsDownloads.length})</span>
              </TabHead>

              <TabPanel>{results(resultsAll)}</TabPanel>
              <TabPanel>{results(resultsNews)}</TabPanel>
              <TabPanel>{results(resultFaqs)}</TabPanel>
              <TabPanel>{results(resultsDownloads)}</TabPanel>
            </Tabs>
          </>
        ) : (
          <h1 className="pt-8">
            <FormattedMessage
              id="search.noresult"
              values={{
                searchterm: <strong>{query}</strong>,
                break: <br />,
              }}
            />
          </h1>
        )}
      </Container>
    </div>
  )
}

SearchResults.propTypes = {
  className: PropTypes.string,
  searchIndex: PropTypes.object.isRequired,
}

export default SearchResults
